<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>登記預約中心</b-breadcrumb-item>
            <b-breadcrumb-item active>預約日曆</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card title="預約日曆">
      <b-overlay :show="showLoading" rounded="sm">
        <b-row no-gutters>
          <b-col sm="4" lg="3">
            <b-form-select v-model="bookingPresetId" :options="bookingPresetsOptions" class="mr-auto mt-2 mb-4" />
          </b-col>
        </b-row>
        <FullCalendar class='demo-app-calendar' :options='calendarOptions'>
          <template v-slot:eventContent='arg'>
            {{ arg.event.extendedProps.id }}
          </template>
        </FullCalendar>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import bookingApi from '@/apis/booking'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      showLoading: false,
      // bookingPresetId: this.$route.query.bookingPresetId ?? null,
      bookingPresets: [],
      bookings: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        events: [],
        eventClick: this.handleEventClick,
      },
    }
  },
  computed: {
    bookingPresetId: {
      get() {
        return this.$route.query.bookingPresetId ?? null
      },
      set(bookingPresetId) {
        this.$router.replace({
          query: {
            bookingPresetId: bookingPresetId ?? undefined
          }
        })
      }
    },
    bookingPresetsOptions() {
      return [{ text: '請選擇', value: null, }, ...this.bookingPresets.map(booking => ({
        text: booking.name,
        value: booking.id,
      }))]
    }
  },
  mounted() {
    this.fetchBookingPresets()
  },
  watch: {
    bookingPresetId: {
      handler(id) {
        if (!id) return
        this.fetchBookings(id)
      },
      immediate: true
    },
  },
  methods: {
    async fetchBookingPresets() {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookingPresets({
          page: 1,
          per_page: 20,
        });
        this.bookingPresets = response.data.data
      } catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    async fetchBookings(presetId) {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookings(presetId, {
          start_at: null,
          end_at: null,
          keyword: '',
        })
        this.bookings = response.data.data
        this.calendarOptions.events = this.bookings.map(booking => {
          return {
            id: booking.id,
            title: booking.data[0].value,
            start: booking.start_at,
            end: booking.end_at,
            extendedProps: booking,
          }
        })
      }
      catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    handleEventClick(arg) {
      this.$router.push({
        name: 'BookingCenterBookingEdit',
        params: {
          id: this.bookingPresetId,
          booking_id: arg.event.extendedProps.id
        }
      })
    }
  }
}
</script>
